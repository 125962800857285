import React, { useState, useEffect, Fragment } from "react";
import { useAppContext } from '../../../../Lib/UserContext'
import { publicsafetydata } from "../../GraphComponents/GraphVariables/PublicSafety";
import { publicsafetymockdata } from "./PublicSafetyData";
import SingleCard from "../../../../Components/Card";
import { StackedBarVariableXAxis } from "../../GraphComponents/StackedBarVariableXAxis";
//import { GroupedBarVariableXGraph } from "../../GraphComponents/GroupedBarVariableXAxis";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
import PublicSafetyTable from "./Tables/PublicSafetyTable";
import PublicSafetyHorizontalTable from "./Tables/PublicSafetyHorizontalTable";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'
// import TableForYearGraphs from '../../../../Components/ReusableTables/TableForYearGraphs'
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const BlueSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#28ADE3",
    },
    "&$checked + $track": {
      backgroundColor: "#28ADE3",
    },
  },
  checked: {},
  track: {},
})(Switch);


export default function PublicSafety(props) {
  const { stateLocation } = useAppContext()
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [graphStackedData, setGraphStackedData] = React.useState([]);
  const [flAgeOffenseToggle, setFlAgeOffenseToggle] = useState(false)
  const [flRaceOffenseToggle, setFlRaceOffenseToggle] = useState(false)
  const [offenderEthnicityToggle, setOffenderEthnicityToggle] = useState(false)
  const [genderOffenseToggle, setGenderOffenseToggle] = useState(false)


  useEffect(() => {
    setGraphData(props.data);
    setGraphStackedData(props.data);
    console.log(props.data)
  }, [props.data]);

  // const addToReports = (data) => {
  //   props.addToReportPage(data);
  // };

  const renderAllCharts = () => {
    return (
      <div>
        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Year of Arrest (by Agency)"
          title="Case and Agency Information  >  Cases by Year of Arrest (by Agency)"
          definitions={[
            [
              "Cases by Year of Arrest (by Agency)",
              "Cases include the locations of the arresting, prosecuting and court agencies. Cases are plotted by year based on the date of arrest, and are counted in the Arresting Agency bar for that year, with the corresponding Prosecuting and/or Court Agency counted in the same year.  For example, if a case has the date of arrest on September 1, 2010, that case is counted in 2010 in the Arresting Agency bar, with its Prosecuting and Court agencies (if known) plotted in the same year. Some arrests do not proceed to prosecution or court charges. Reporting of Arresting and Court agencies is required, but not Prosecuting agencies. Consequently, some years have fewer Prosecuting agencies than Court agencies. Given the time lag for cases to move from arrest to prosecution to court, recent years show a steeper decline from Arresting to Prosecuting and Court agencies. For example, a case with an arrest in 2019 may not have proceeded to prosecution or court, so it will only be counted in the Arresting Agency bar for that year. ",
            ],
          ]}
          graph={
            <GroupedBarVariableXGraph
              data={props.data.casesYearAgency}
              keys={publicsafetydata.casesYearAgency}
              xaxis="Year of Arrest"
              yaxis="Number of Cases"
              trendLine={true}
              trendLineLegend="Total Cases"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.casesYearAgency}
              keys={publicsafetydata.casesYearAgency}
              id="Cases by Year of Arrest (by Agency)"
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Year of Arrest"
          title="Case and Agency Information  >  Cases by Year of Arrest"
          definitions={[
            [
              "Cases by Year of Arrest",
              "Cases are plotted by year based on the date of arrest. Cases are organized by the penal code chapter of the offense at arrest.",
            ],
          ]}
          graph={
            <StackedBarVariableXAxis
              data={props.data.casesYearPenal}
              keys={publicsafetydata.casesYearPenal}
              xaxis="Year of Arrest"
              yaxis="Number of Cases"
              trendLine={true}
              trendLineLegend="Total Cases"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.casesYearPenal}
              keys={publicsafetydata.casesYearPenal}
              id="Cases by Year of Arrest"
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Year of Arrest (Top Ten Offenses)"
          title="Case and Agency Information  >  Cases by Year of Arrest (Top Ten Offenses)"
          definitions={[
            [
              "Cases by Year of Arrest (Top Ten Offenses)",
              "Cases are plotted by year based on the date of arrest. The chart is based on the top ten offenses over all cases. The “Offenses” filter is not applicable for this chart.",
            ],
          ]}
          graph={
            <StackedBarVariableXAxis
              data={props.data.casesYearOffense}
              keys={publicsafetydata.casesYearOffense}
              xaxis="Year of Arrest"
              yaxis="Number of Cases"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.casesYearOffense}
              keys={publicsafetydata.casesYearOffense}
              id="Cases by Year of Arrest (Top Ten Offenses)"
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Arresting Agency"
          title="Case and Agency Information  >  Cases by Arresting Agency"
          definitions={[
            ["", "This chart displays the top ten arresting agencies ranked by number of arrests in descending order, subject to selected filters. All filters apply to this chart."]
          ]}
          graph={
            <HorizontalBarChart
              data={props.data.agencyOria}
              value="agency_oria_agency"
              xaxis="Number of Cases"
              yaxis="Top Ten Agencies"
              smalltext
              yLabelsWidth={175}
            />
          }
          table={
            <PublicSafetyHorizontalTable
              name='Arresting Agency'
              data={props.data.agencyOria}
              reverseOrder={true}
            />
          }
          addToReports={props.addToReportPage}
        />

        {/* Horizontal Bar Chart */}
        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Agency (Prosecuting Agency (ORIP))"
          title="Case and Agency Information  >  Cases by Agency (Prosecuting Agency (ORIP))"
          definitions={[
            [
              "Cases by Agency (Prosecuting Agency (ORIP))",
              "The top ten prosecuting agencies are ranked by number of cases, based on selected Offense filters.",
            ],
          ]}
          graph={
            <HorizontalBarChart
              data={props.data.agencyOrip}
              value="agency_orip_agency"
              xaxis="Number of Cases"
              yaxis="Top Ten Agencies"
              smalltext={true}
            />
          }
          table={
            <PublicSafetyHorizontalTable
              data={props.data.agencyOrip}
              reverseOrder={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {props.stateLocation === 'LA' &&
          // {/* Horizontal Bar Chart */}
          <SingleCard
            width="100%"
            id="Case and Agency Information Cases by Court Agency"
            title="Case and Agency Information  >  Cases by Court Agency"
            definitions={[
              ["", "This chart displays the top ten court agencies ranked by number of cases in descending order, subject to selected filters. All filters apply to this chart."]
            ]}
            graph={
              <HorizontalBarChart
                data={props.data.agencyOric}
                value="agency_oric_agency"
                xaxis="Number of Cases"
                yaxis="Top Ten Agencies"
                smalltext={true}
              />
            }
            table={
              <PublicSafetyHorizontalTable
                name='Court Agency'
                data={props.data.agencyOric}
                reverseOrder={true}
              />
            }
            addToReports={props.addToReportPage}
          />
        }

        <div>
          <SingleCard
            // className={classes.card}
            width="100%"
            title="Case and Agency Information  >  Arrest Cases by Location and Population"
            id="Case and Agency Information Arrest Cases by Location and Population"
            graph={
              <HorizontalBarChart
                percentOfTotal={props.data.arrestCasesByLocationPop}
                data={props.data.arrestCasesByLocationPop?.slice(0, 10)} // get top 10 counties for the chart
                value="city"
                xaxis="Number of Cases per 100,000"
                yaxis="Locations"
              />
            }
            definitions={[["", "This chart displays the top ten locations ranked by number of arrests per 100,000 population in descending order, subject to selected filters. The percentage displayed on the chart hovers represents the proportion of arrests per 100,000 population for a specific location compared to the total arrests per 100,000 population for all locations. The accompanying table displays cases for all locations per 100,000 population. All filters apply to this chart."]]}
            table={
              <TableWithTotal
                id="Case and Agency Information Arrest Cases by Location and Population"
                name='Arrest Cases by Location and Population'
                data={props.data.arrestCasesByLocationPop}
                descending
                toFixed={2}
                headers={['Location', 'Number of Cases by Location and Population']}
                columnKeys={['city', 'total']}
                totalKey='total'
              />
            }
            addToReports={props.addToReportPage}
          />
        </div>

        {(props.stateLocation === 'LA' || props.stateLocation === 'FL') &&
          <SingleCard
            // className={classes.card}
            width="100%"
            id="Case and Agency Information Cases by Year of Arrest (by Offense Group)"
            title="Case and Agency Information  >  Cases by Year of Arrest (by Offense Group)"
            definitions={[["", "This chart shows the number of arrest cases over time grouped by Offense groups. The Arrest Year filter does not apply to this chart. All other filters apply."]]}
            graph={
              <StackedBarVariableXAxis
                data={props.data.casesYearOffense}
                keys={props.stateLocation === 'TX' ? publicsafetydata.casesYearOffenseTX : props.stateLocation === 'LA' ? publicsafetydata.casesYearOffenseLA : publicsafetydata.casesYearOffenseFL}
                xaxis="Years"
                yaxis="Number of Cases"
              // differentYaxisMaxVars={props.stateLocation === 'TX' ? ['offense1', 'offense2','offense3','offense4', 'offense5', 'offense6', 'offense7', 'offense8', 'offense9', 'offense10'] : ['offense1', 'offense2','offense3','offense4']}
              />
            }
            table={
              <TableWithTotal
                id="Cases by Year of Arrest (by Offense Group)"
                name='Cases by Year of Arrest (by Offense Group)'
                data={props.data.casesYearOffense}
                toFixed={0}
                ascending
                headers={props.stateLocation === 'TX' ? ['Year', 'Indecency with a Child - Sexual Contact/Exposure of Genitals with Intent to Gratify', 'Aggravated Sexual Assault Child', 'Prostitution (before 2015)', 'Sexual Assault of a Child', 'Prostitution 1+ Convictions (before 2015)', 'Sexual Assault', 'Prostitution - Seller', 'Possession of Child Pornography with 1+ Previous Conviction', 'Continuous Sex Abuse of a Child', 'Prostitution 1+ Convictions - Seller', 'Total'] : props.stateLocation === 'LA' ? ['Year', 'Trafficking of Persons', 'Internet Crimes Against Children', 'Sexual Assault Against Minors', 'Prostitution Offenses', 'Total'] : ['Year', 'Human Trafficking', 'Prostitution', 'Unknown', 'Total']}
                columnKeys={props.stateLocation === 'TX' ? ['xaxis', 'offense1', 'offense2', 'offense3', 'offense4', 'offense5', 'offense6', 'offense7', 'offense8', 'offense9', 'offense10', 'total'] : props.stateLocation === 'LA' ? ['xaxis', 'offense1', 'offense2', 'offense3', 'offense4', 'total'] : ['xaxis', 'offense1', 'offense2', 'offense3', 'total']}
                totalKey='total'
                totalEachColumn
              />
            }
            addToReports={props.addToReportPage}
          />
        }

        {/* Horizontal Bar Chart */}
        {/* <SingleCard
            width="100%"
            id="Case and Agency Information Cases by Year of Arrest (by Agency)"
            title="Case and Agency Information  >  Cases by Year of Arrest (by Agency)"
            definitions={[
              [
                "Cases by Year of Arrest (by Agency)",
                "Cases include the locations of the arresting, prosecuting and court agencies. Cases are plotted by year based on the date of arrest, and are counted in the Arresting Agency bar for that year, with the corresponding Prosecuting and/or Court Agency counted in the same year.  For example, if a case has the date of arrest on September 1, 2010, that case is counted in 2010 in the Arresting Agency bar, with its Prosecuting and Court agencies (if known) plotted in the same year. Some arrests do not proceed to prosecution or court charges. Reporting of Arresting and Court agencies is required, but not Prosecuting agencies. Consequently, some years have fewer Prosecuting agencies than Court agencies. Given the time lag for cases to move from arrest to prosecution to court, recent years show a steeper decline from Arresting to Prosecuting and Court agencies. For example, a case with an arrest in 2019 may not have proceeded to prosecution or court, so it will only be counted in the Arresting Agency bar for that year. ",
              ],
            ]}
            graph={
              <GroupedBarVariableXGraph
                data={props.data.casesYearAgency}
                keys={publicsafetydata.casesYearAgency}
                xaxis="Year of Arrest"
                yaxis="Number of Cases"
                trendLine={true}
                trendLineLegend="Total Cases"
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.casesYearAgency}
                keys={publicsafetydata.casesYearAgency}
                id="Cases by Year of Arrest (by Agency)"
              />
            }
            addToReports={props.addToReportPage}
          />

          <SingleCard
            width="100%"
            id="Case and Agency Information Cases by Year of Arrest (by Penal Code Chapter)"
            title="Case and Agency Information  >  Cases by Year of Arrest (by Penal Code Chapter)"
            definitions={[
              [
                "Cases by Year of Arrest (by Penal Code Chapter)",
                "Cases are plotted by year based on the date of arrest. Cases are organized by the penal code chapter of the offense at arrest.",
              ],
            ]}
            graph={
              <StackedBarVariableXAxis
                data={props.data.casesYearPenal}
                keys={publicsafetydata.casesYearPenal}
                xaxis="Year of Arrest"
                yaxis="Number of Cases"
                trendLine={true}
                trendLineLegend="Total Cases"
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.casesYearPenal}
                keys={publicsafetydata.casesYearPenal}
                id="Cases by Year of Arrest (by Penal Code Chapter)"
              />
            }
            addToReports={props.addToReportPage}
          />

        {/* Demographics */}
        {/* <SingleCard
          width="100%"
          id="Demographics Age (by Penal Code Chapters)"
          title="Demographics  >  Age (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXAxis
              data={props.data.agePenalCode}
              keys={publicsafetydata.agePenalCode}
              xaxis="Age at Offense"
              yaxis="Number of Individuals"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.agePenalCode}
              keys={publicsafetydata.agePenalCode}
              id="Age (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* #444 */}
        {stateLocation === 'FL' ?
          <SingleCard
            width="100%"
            id="Offender Demographics Age"
            title="Offender Demographics  >  Age"
            underTitleContent={
              <div className='flexRowCenter'>
                <BlueSwitch
                  checked={flAgeOffenseToggle}
                  color="primary"
                  onChange={() => setFlAgeOffenseToggle(!flAgeOffenseToggle)}
                  name="flAgeOffenseToggle"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>{flAgeOffenseToggle ? 'By Age' : 'By Arrest Type'}</p>
              </div>
            }
            graph={
              <StackedBarVariableXAxis
                wrapText
                width={700}
                data={flAgeOffenseToggle ? props.data.ageOffense2 : props.data.ageOffense}
                activeCategory={flAgeOffenseToggle} // only for choosing report data
                xaxis="Age at Offense"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Offender Demographics Age"
                name='Offender Demographics Age'
                data={props.data.ageOffenseTable || []}
                headers={['Arrest Type', 'Age', 'Value']}
                columnKeys={['type', 'age', 'total']}
                totalKey='total'
              />
            }
            definitions={[["", "This chart displays the age of individuals at the time of arrest, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
          :
          <SingleCard
            width="100%"
            id="Offender Demographics Age"
            title="Offender Demographics  >  Age"
            definitions={[["", "This chart displays the age of individuals at the time of arrest, subject to selected filters. All filters apply to this chart."]]}
            graph={
              <SingleBarGraph
                data={
                  props.data.ageOffense
                    ? props.data.ageOffense
                    : publicsafetymockdata.ageOffense
                }
                keys={publicsafetydata.ageOffenseCode}
                xaxis="Age at Offense"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.ageOffense}
                keys={publicsafetydata.ageOffenseCode}
                id="Age"
                single={true}
              />
            }
            addToReports={props.addToReportPage}
          />
        }

        {/* <SingleCard
          width="100%"
          id="Demographics Race (by Penal Code Chapters)"
          title="Demographics  >  Race (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXAxis
              data={props.data.racePenalCode}
              keys={publicsafetydata.racePenalCode}
              xaxis="Race"
              yaxis="Number of Individuals"
              smalltext={true}
              wrapText={true}
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.racePenalCode}
              keys={publicsafetydata.racePenalCode}
              id="Race (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {props.stateLocation === 'FL' &&
          <SingleCard
            width="100%"
            id="Offender Demographics Race"
            title="Offender Demographics  >  Race"
            underTitleContent={
              <div className='flexRowCenter'>
                <BlueSwitch
                  checked={flRaceOffenseToggle}
                  color="primary"
                  onChange={() => setFlRaceOffenseToggle(!flRaceOffenseToggle)}
                  name="flRaceOffenseToggle"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>{flRaceOffenseToggle ? 'By Race' : 'By Arrest Type'}</p>
              </div>
            }
            graph={
              <StackedBarVariableXAxis
                wrapText
                width={700}
                data={flRaceOffenseToggle ? props.data.raceOffense2 : props.data.raceOffense}
                activeCategory={flRaceOffenseToggle} // only for choosing report data
                xaxis="Race"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Offender Demographics Race"
                name='Offender Demographics Race'
                data={props.data.raceOffenseTable || []}
                headers={['Arrest Type', 'Race', 'Value']}
                columnKeys={['type', 'race', 'total']}
                totalKey='total'
              />
            }
            definitions={[["", "This chart displays the race of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
        }

        {props.stateLocation === 'LA' &&
          <SingleCard
            width="100%"
            id="Offender Demographics Race"
            title="Offender Demographics  >  Race"

            graph={
              <SingleBarGraph
                data={props.data.raceOffense || []}
                keys={publicsafetydata.raceOffenseCode}
                xaxis="Race"
                yaxis="Number of Individuals"
                type="cc"
                smalltext={true}
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.raceOffense || []}
                keys={publicsafetydata.raceOffenseCode}
                id="Race"
                single={true}
              />
            }
            definitions={[["", "This chart displays the race of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
        }

        {props.stateLocation === 'FL' &&
          <SingleCard
            width="100%"
            id="Offender Demographics Ethnicity"
            title="Offender Demographics  >  Ethnicity"
            underTitleContent={
              <div className='flexRowCenter'>
                <BlueSwitch
                  checked={offenderEthnicityToggle}
                  color="primary"
                  onChange={() => setOffenderEthnicityToggle(!offenderEthnicityToggle)}
                  name="offenderEthnicityToggle"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>{offenderEthnicityToggle ? 'By Ethnicity' : 'By Arrest Type'}</p>
              </div>
            }
            graph={
              <StackedBarVariableXAxis
                wrapText
                width={700}
                data={offenderEthnicityToggle ? props.data.offenderEthnicity2 : props.data.offenderEthnicity}
                activeCategory={offenderEthnicityToggle} // only for choosing report data
                xaxis="Ethnicity"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Offender Demographics Ethnicity"
                name='Offender Demographics Ethnicity'
                data={props.data.offenderEthnicityTable || []}
                headers={['Arrest Type', 'Ethnicity', 'Value']}
                columnKeys={['type', 'ethnicity', 'total']}
                totalKey='total'
              />
            }
            definitions={[["", "This chart displays the ethnicity of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
        }

        {stateLocation === 'LA' &&
          <SingleCard
            width="100%"
            id="Offender Demographics Ethnicity"
            title="Offender Demographics  >  Ethnicity"
            graph={
              <SingleBarGraph
                data={props.data.offenderEthnicity ?? []}
                xaxisVar='source'
                labelOnHover
                xaxis="Ethnicity"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Ethnicity"
                data={props.data.offenderEthnicity ?? []}
                headers={['Ethnicity', 'Number of Individuals']}
                columnKeys={['source', 'value']}
              />
            }
            definitions={[["", "This chart displays the ethnicity of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
        }

        {/* <SingleCard
          width="100%"
          id="Demographics Ethnicity (by Penal Code Chapters)"
          title="Demographics  >  Ethnicity (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXAxis
              data={props.data.ethnicityPenalCode}
              keys={publicsafetydata.ethnicityPenalCode}
              xaxis="Ethnicity"
              yaxis="Number of Individuals"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.ethnicityPenalCode}
              keys={publicsafetydata.ethnicityPenalCode}
              id="Ethnicity (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* 
        <SingleCard
          width="100%"
          id="Demographics Gender (by Penal Code Chapters)"
          title="Demographics  >  Gender (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXAxis
              data={props.data.genderPenalCode}
              keys={publicsafetydata.genderPenalCode}
              xaxis="Gender"
              yaxis="Number of Individuals"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.genderPenalCode}
              keys={publicsafetydata.genderPenalCode}
              id="Gender (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {props.stateLocation === 'TX' &&
          <SingleCard
            width="100%"
            id="Offender Demographics Race/Ethnicity"
            title="Offender Demographics  >  Race/Ethnicity"
            graph={
              <SingleBarGraph
                data={props.data.offenderRaceEthnicity ?? []}
                xaxisVar='source'
                xaxis="Race"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Race/Ethnicity"
                data={props.data.offenderRaceEthnicity ?? []}
                headers={['Race/Ethnicity', 'Value']}
                columnKeys={['source', 'value']}
              />
            }
            definitions={[["", "This chart displays the race or ethnicity of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
        }

        {stateLocation === 'FL' ?
          <SingleCard
            width="100%"
            id="Offender Demographics Gender"
            title="Offender Demographics  >  Gender"
            underTitleContent={
              <div className='flexRowCenter'>
                <BlueSwitch
                  checked={genderOffenseToggle}
                  color="primary"
                  onChange={() => setGenderOffenseToggle(!genderOffenseToggle)}
                  name="genderOffenseToggle"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>{genderOffenseToggle ? 'By Gender' : 'By Arrest Type'}</p>
              </div>
            }
            graph={
              <StackedBarVariableXAxis
                wrapText
                width={700}
                data={genderOffenseToggle ? props.data.genderOffense2 : props.data.genderOffense}
                activeCategory={genderOffenseToggle} // only for choosing report data
                xaxis="Gender"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Offender Demographics Gender"
                name='Offender Demographics Gender'
                data={props.data.genderOffenseTable || []}
                headers={['Arrest Type', 'Gender', 'Value']}
                columnKeys={['type', 'gender', 'total']}
                totalKey='total'
              />
            }
            definitions={[["", "This chart displays the gender of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
          :
          <SingleCard
            width="100%"
            id="Offender Demographics Gender"
            title="Offender Demographics  >  Gender"
            graph={
              <SingleBarGraph
                data={props.data.genderOffense ?? []}
                keys={publicsafetydata.genderOffenseCode}
                xaxis="Gender"
                yaxis="Number of Individuals"
                type="cc"
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.genderOffense}
                keys={publicsafetydata.genderOffenseCode}
                id="Gender"
                single={true}
              />
            }
            definitions={[["", "This chart displays the gender of arrested individuals, subject to selected filters. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />
        }

        {/* <SingleCard
          width="100%"
          id="Demographics Citizenship (by Penal Code Chapters)"
          title="Demographics  >  Citizenship (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXAxis
              data={props.data.citizenshipPenalCode}
              keys={publicsafetydata.citizenshipPenalCode}
              xaxis="Citizenship"
              yaxis="Number of Individuals"
              smalltext={true}
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.citizenshipPenalCode}
              keys={publicsafetydata.citizenshipPenalCode}
              id="Citizenship (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Demographics Citizenship (by Offense)"
          title="Demographics  >  Citizenship (by Offense)"
          graph={
            <StackedBarVariableXAxis
              data={props.data.citizenshipOffense}
              keys={publicsafetydata.citizenshipOffenseCode}
              xaxis="Citizenship"
              yaxis="Number of Individuals"
              smalltext={true}
              noLegend={true}
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.citizenshipOffense}
              keys={publicsafetydata.citizenshipOffenseCode}
              id="Citizenship (by Offense)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}
      </div>
    );
  }

  const renderSingleChart = () => {
    const charts = renderAllCharts();
    const singleCards = charts.props.children.props.children;

    const index = singleCards.findIndex((card) => card.props.id === props.chartId);
    if (index <= -1) {
      return <Fragment></Fragment>;
    }

    const elementToRender = singleCards[index];
    return <SingleCard
      {...elementToRender.props}
      title={props.chartTitle || props.title}
      description={props.chartInsight || ""}
      shouldUseNewStyles={true}
    />;
  }

  return (
    props.chartId
      ? renderSingleChart()
      : renderAllCharts()
  );
}
