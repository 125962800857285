import React, { useState } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
// import { Checkbox } from '../../../Components/Checkbox'


export const HeatMapChart = (props) => {
    const { chartColorArray } = useAppContext()
    const [tooltipData, setTooltipData] = useState(null)

    let margin = { top: 20, right: props.report ? 75 : 100, bottom: 60, left: 160 }
    let width;
    let height = 500;
    if (window.innerWidth > 1200 && window.innerWidth < 1920) {
        width = 850; // 900
    } else {
        width = 1200;
    }
    if (props.report) {
        width = 650
    }
    // bounds = area inside the axis
    const boundsWidth = width - margin.right - margin.left;
    const boundsHeight = height - margin.top - margin.bottom;

    let data = props.data
    // groups
    const allYGroups = data.map(d => d.y)
    const allXGroups = data.map((d) => d.x)

    // x and y scales
    const xScale = d3
        .scaleBand()
        .range([0, boundsWidth])
        .domain(allXGroups)
        .padding(0.01);

    const yScale = d3
        .scaleBand()
        .range([boundsHeight, 0])
        .domain(allYGroups)
        .padding(0.01);

    // Color scale
    const colorScale = d3
        .scaleLinear()
        .range([chartColorArray[1], chartColorArray[0], chartColorArray[4]]) // my colors copying the model
        // .range(['#051229', '#A5E3ED']) // their colors are off
        .domain([1, 6, 10]);


    // Build the rectangles
    const allRects = data.map((d, i) => {
        return (
            <rect
                key={i}
                r={4}
                x={xScale(d.x)}
                y={yScale(d.y)}
                width={xScale.bandwidth()}
                height={yScale.bandwidth()}
                opacity={1}
                fill={d.value === 0 ? '#fff' : colorScale(d.value)}
                rx={0}
                stroke={"white"}
                onMouseEnter={(e) => {
                    if (d.value > 0) {
                        setTooltipData({
                            text1: d[props.hoverText[0].value],
                            text2: d[props.hoverText[1].value],
                            text3: d[props.hoverText[2].value],
                            xPos: xScale(d.x) + xScale.bandwidth() + margin.left,
                            yPos: yScale(d.y), //  + xScale.bandwidth() / 2 + margin.top,
                        });
                    } else {
                        return
                    }
                }}
                onMouseLeave={() => setTooltipData(null)}
            />
        );
    });

    const valueText = data.map((d, i) => {
        const xPos = xScale(d.x)
        const yPos = yScale(d.y)
        if (d.value !== 0) {
            return (
                <text
                    key={i}
                    x={xPos + xScale.bandwidth() / 2}
                    y={yPos + yScale.bandwidth() / 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={10}
                    style={{ fill: d.value >= 8 ? '#000' : '#fff' }}
                >
                    {d.value}
                </text>
            )
        } else {
            return null
        }
    });

    const xLabels = allXGroups.map((name, i) => {
        const xPos = xScale(name) ?? 0;
        return (
            <text
                key={i}
                x={xPos + xScale.bandwidth() / 2}
                y={boundsHeight + 20}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={10}
            >
                {name}
            </text>
        );
    });

    const yLabels = allYGroups.map((name, i) => {
        const yPos = yScale(name) ?? 0;
        return (
            <text
                key={i}
                x={-15}
                y={yPos + yScale.bandwidth() / 2}
                textAnchor="end"
                dominantBaseline="middle"
                fontSize={10}
            >
                {name}
            </text>
        );
    });


    return (
        <div id={props.id} style={{ position: 'relative', display: 'inline-flex', flexDirection: 'row', verticalAlign: 'top'}}>
            <svg width={width} height={height}>
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    transform={`translate(${[margin.left, margin.top].join(",")})`}
                >
                    {allRects}
                    {valueText}
                    {xLabels}
                    {yLabels}

                    <text
                        x={(boundsWidth / 2) - (props.xaxis.length * 3.5)}
                        y={height - 20}
                        fontSize={10}
                    >
                        {props.xaxis}
                    </text>
                </g>

                <text
                    x={(0 - height / 2) - (props.yaxis.length * 3.5)}
                    y={10}
                    fontSize={10}
                    transform='rotate(-90)'
                >
                    {props.yaxis}
                </text>

                {/* right side Legend  */}
                <text
                    x={(0 - height / 2) - (props.legendText.length * 3.5)}
                    y={width - 20}
                    fontSize={10}
                    transform='rotate(-90)'
                >
                    {props.legendText}
                </text>

            </svg>

            {/* Right side legend */}
            <div style={{ height: boundsHeight, position: 'relative', display: 'inline-flex', flexDirection: 'row', marginTop: margin.top, verticalAlign: 'top' }}>
                {/* <span style={{ height: 'fit-content', transform: 'rotate(-90deg)', alignSelf: 'center', justifySelf: 'flex-end'}}>{props.legendText}</span> */}
                <div style={{ width: '25px', height: '100%', background: `linear-gradient(${[chartColorArray[1], chartColorArray[0], chartColorArray[4]]})` }} />
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: '#000', marginLeft: '5px' }}>
                    <span style={{ textAlign: 'right' }}>1</span>
                    <span style={{ textAlign: 'right' }}>10</span>
                </div>
            </div>

            {/* Tooltip */}
            <div style={{ opacity: tooltipData ? 1 : 0, minWidth: tooltipData ? '150px' : '0px', position: 'absolute', top: tooltipData?.yPos || 0, left: tooltipData?.xPos || 0, backgroundColor: '#fff', border: '2px solid #000', borderRadius: '5px', padding: '5px', color: '#000' }}>
                {tooltipData?.text1 &&
                    <p style={{ margin: '0px' }}><strong>{tooltipData?.text1}</strong></p>
                }
                {tooltipData?.text2 &&
                    <p style={{ margin: '0px' }}>{props.hoverText?.[1].name ? `${props.hoverText?.[1].name}: ` : ''}{tooltipData?.text2}</p>
                }
                {tooltipData?.text3 &&
                    <p style={{ margin: '0px' }}>{props.hoverText?.[2].name ? `${props.hoverText?.[2].name}: ` : ''}{tooltipData?.text3}</p>
                }
            </div>
        </div>
    );
};
