import React, { useState, Fragment } from "react";
import { useAppContext } from '../../../../Lib/UserContext'
import { imbdata } from "../../GraphComponents/GraphVariables/BusinessesOfInterestData";
import { imbmockdata } from "./ImbData";
import SingleCard from "../../../../Components/Card";
import { StackedBarVariableXAxis } from "../../GraphComponents/StackedBarVariableXAxis";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
//import { GroupedBarVariableXGraph } from "../../GraphComponents/GroupedBarVariableXAxis";
import ImbTable from "./Tables/ImbTable";
import ImbHorizontalTable from "./Tables/ImbHorizontalTable";
import ImbTotalsTable from "./Tables/ImbTotalsTable";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import CardWithDrawer from "../../../../Components/CardWithDrawer";
import ConnectedScatterplot from "../../GraphComponents/ConnectedScatterplot";
import { HeatMapChart } from "../../GraphComponents/HeatMapChart";
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const BlueSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#28ADE3",
    },
    "&$checked + $track": {
      backgroundColor: "#28ADE3",
    },
  },
  checked: {},
  track: {},
})(Switch);


export default function BusinessesOfInterest(props) {
  const { stateLocation } = useAppContext()
  const [timeVisitAmountPaid, setTimeVisitAmountPaid] = useState(false)

  // console.log('data', props.data);

  const renderAllCharts = () => {
    return (
      <div>
        <div>
          <SingleCard
            width="100%"
            id="Business Information Businesses by Year"
            title="Business Information  >  Businesses by Year"
            graph={
              <SingleBarGraph
                singleColor
                imbByYear
                data={
                  props.data.businessesByYear
                    ? props.data.businessesByYear
                    : imbmockdata.businessByYear
                  // props.data.businessesByYear
                  // ? props.data.businessesByYear.sort((a, b) => {
                  //     if (a.total === b.total) {
                  //       return a.city > b.city ? -1 : 1;
                  //     }
                  //     return a.total > b.total ? -1 : 1;
                  //   })
                  // : imbmockdata.businessByYear.sort((a, b) => {
                  //     if (a.total === b.total) {
                  //       return a.city > b.city ? -1 : 1;
                  //     }
                  //     return a.total > b.total ? -1 : 1;
                  //   })
                }
                keys={stateLocation === 'FL' ? imbdata.businessYearFL : imbdata.businessYear}
                xaxis="Year"
                yaxis="Number of Businesses"
                trendLine={true}
                trendLineLegend="Total Businesses"
                type="cc"
              />
            }
            definitions={[
              [
                "Businesses by Year",
                "This chart shows the total number of suspected IMBs per year, and can only be filtered by Location; other filters are not applicable.",
              ],
            ]}
            table={
              <ImbTable
                data={props.data.businessesByYear}
                keys={stateLocation === 'FL' ? imbdata.businessYearFL : imbdata.businessYear}
                id="Business by Year"
                single={true}
              />
            }
            addToReports={props.addToReportPage}
          />
          <SingleCard
            width="100%"
            id="Business Information Businesses by Location"
            title="Business Information  >  Businesses by Location"
            graph={
              <HorizontalBarChart
                // data={
                //   props.data.businessesByLocation
                //     ? props.data.businessesByLocation
                //     : imbmockdata.businessByLocation
                // }
                percentOfTotal={props.data.businessByLocationAll}
                data={
                  props.data.businessesByLocation
                    ? props.data.businessesByLocation.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city > b.city ? -1 : 1;
                        }
                        return a.total > b.total ? -1 : 1;
                      })
                    : imbmockdata.businessByLocation.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city > b.city ? -1 : 1;
                        }
                        return a.total > b.total ? -1 : 1;
                      })
                }
                value="city"
                xaxis="Number of Businesses"
                yaxis="Location"
              />
            }
            definitions={[
              [
                "Businesses by Location",
                "This chart shows the top ten locations based on the count of suspected IMBs per city, based on selected filters, as of the most recent quarterly data snapshot. The percent is the number of businesses by location out of the total businesses. All filters apply to this chart.",
              ],
            ]}
            table={
              <ImbHorizontalTable
                data={props.data.businessByLocationAll}
                id="Business by Location"
                reverseOrder={true}
              />
            }
            addToReports={props.addToReportPage}
          />
          <SingleCard
            width="100%"
            id="Business Information Businesses by Location and Population"
            title="Business Information  >  Businesses by Location and Population"
            graph={
              <HorizontalBarChart
                percentOfTotal={props.data.businessesByLocationPop ?? []}
                data={props.data.businessesByLocationPop?.slice(0, 10) ?? []}
                value="city"
                xaxis="Number of Businesses per 100,000 Population"
                yaxis="Location"
              />
            }
            table={
              <ImbHorizontalTable
                noTotalRow
                decimalPoint
                data={props.data.businessesByLocationPop}
                id="Business by Location and Pop"
                reverseOrder={true}
              />
            }
            definitions={[["", "This chart shows the top ten locations based on the count of suspected IMBs per 100,000 population per city, based on selected filters, as of the most recent quarterly data snapshot. City population data is from the <a href='https://www.census.gov/en.html' target='_blank' rel='noopener noreferrer'>United States Census Bureau</a>. The percent is the number of businesses by location per 100,000 population out of all businesses per 100,000 population. All filters apply to this chart."]]}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            width="100%"
            id="Business Information Businesses by Region and Year"
            title="Business Information  >  Businesses by Region and Year"
            graph={
              <ConnectedScatterplot
                data={props.data.byRegionAndYear ?? []}
                xaxisKey='year'
                yaxisKey='total'
                xaxis='Year'
                yaxis='Number of Businesses'
                legend
                legendKey='region'
                hoverItems={['Businesses:', 'total', 'Growth Rate:', 'growth_rate']}
              />
            }
            table={
              <TableWithTotal
                id="Businesses by Region and Year"
                data={props.data.byRegionAndYear ?? []}
                tableTotal={props.data.byRegionAndYearTableTotal ?? 0}
                headers={['Year', 'Region', 'Growth Rate', 'Number of Businesses']}
                columnKeys={['year', 'region', 'growth_rate', 'total']}
              />
            }
            definitions={[["", 'This chart shows the number of businesses by region over time. Hover over a data point to view the growth rate compared to the previous year. No filters apply to this chart.']]}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            width="100%"
            id="Business Information Top Ten Locations By Year"
            title="Business Information  >  Top Ten Locations By Year"
            graph={
              <HeatMapChart
                data={props.data.topLocationsByYear || []}
                xaxis='Year'
                yaxis='Location'
                legendText='Location Rank'
                hoverText={[{ name: '', value: 'y' }, { name: 'Year', value: 'x' }, { name: 'Rank', value: 'value' }]}
              />
            }
            table={
              <TableWithTotal
                noTotalRow
                id="Top Ten Locations By Year"
                data={props.data.topLocationsByYearTable || []}
                headers={stateLocation !== 'FL' ? ['Rank', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'] : ['Rank', '2019', '2020', '2021', '2022', '2023', '2024']}
                columnKeys={stateLocation !== 'FL' ? ['rank', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'] : ['rank', '2019', '2020', '2021', '2022', '2023', '2024']}
              />
            }
            definitions={[["", 'This chart visually tracks the top ten counties with the highest number of IMBs over time, using color gradients to represent the density of these businesses in each location. The darker blue values indicate areas with the highest concentration of IMBs, while the lighter green values represent those with the fewest within the top 10. Select a specific region from the location filter to view the top ten locations from that region. No other filters apply.']]}
            addToReports={props.addToReportPage}
          />

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <CardWithDrawer
              data={props.data.totals}
              width="35vw"
              id="Business Information Total Businesses"
              title="Business Information  >  Total Businesses"
              category="OVC Measures"
              value={[
                {
                  data: props.data?.totals?.locations,
                  text: "Total Locations",
                },
                {
                  data: props.data?.totals?.businesses,
                  text: "Total Businesses",
                },
              ]}
              definitions={[["", "This chart lists all suspected IMBs by business name and address, based on selected filters, as of the most recent quarterly data snapshot. Total Locations refers to each unique city in which there is one or more suspected IMB."]]}
              table={<ImbTotalsTable title="Total Businesses" data={props.data.orgs} />}
              addToReports={props.addToReportPage}
            />
          </div>

          <SingleCard
            width="100%"
            id="Buyer Information Time of Visit and Amount Paid"
            title="Buyer Information  >  Time of Visit and Amount Paid"
            underTitleContent={
              <div className='flexRowCenter'>
                <BlueSwitch
                  checked={timeVisitAmountPaid}
                  color="primary"
                  onChange={() => setTimeVisitAmountPaid(!timeVisitAmountPaid)}
                  name="timeVisitAmountPaid"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>{!timeVisitAmountPaid ? 'By Time of Visit' : 'By Amount Paid'}</p>
              </div>
            }
            graph={
              <StackedBarVariableXAxis
                wrapText
                wrapTextWidth={140}
                data={timeVisitAmountPaid ? props.data?.amountPaid : props.data?.timeVisit ?? []}
                activeCategory={timeVisitAmountPaid} // only for choosing report data
                xaxis={timeVisitAmountPaid ? 'Amount Paid' : 'Time of Visit'}
                yaxis='Number of Buyers'
                type="cc"
              />
            }
            table={
              <TableWithTotal
                id="Time of Visit and Amount Paid"
                data={props.data.timeVisitAmountPaidTable || []}
                headers={['Time of Visit', 'Amount Paid', 'Number of Buyers']}
                columnKeys={['prop1', 'prop2', 'total']}
              />
            }
            definitions={[["", "This chart shows the total amount that buyers paid stacked by the time of day visited, based on selected filters. Interact with the toggle to view the distribution of time of day visited by amount paid. Only the Location filter applies to this chart."]]}
            addToReports={props.addToReportPage}
          />

        </div>
      </div>
    );
  }

  const renderSingleChart = () => {
      const charts = renderAllCharts();
      const singleCards = charts.props.children.props.children;

      const index = singleCards.findIndex((card) => card.props.id === props.chartId);
      if (index <= -1) {
          return <Fragment></Fragment>;
      }

      const elementToRender = singleCards[index];
      return <SingleCard
          {...elementToRender.props}
          title={props.chartTitle || props.title}
          description={props.chartInsight || ""}
          shouldUseNewStyles={true}
      />;
  }

  return (
      props.chartId
          ? renderSingleChart()
          : renderAllCharts()
  );
}
