import { parishData as parishJson } from '../Data/parishJson'
// import { laDistrictJson } from "../Data/laDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'
import { parishData } from "..//Data/parishJson";
import { laRegionData } from "../Data/laRegionJson";
// import { laRegionData } from '../Data/newLARegions' // older?
import { laOrgList as laScreeningsOrgs } from "../Data/laOrgList";
import { parishList } from "../Data/parishList";
import { parishList2 } from "../Data/parishList";
import { laRegionList } from "../Data/laRegionList";

export const mapsLAData = {
  mapZoom: 5,
  reportMapZoom: 4,
  mapBoxCenter: [-92.329102, 30.39183],
  mapBoxCenterReport: [-92.329102, 30.39183],
  mapMaxBounds: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
  mapMaxBoundsReport: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
  reportMapWidth: '577px',

  'Services': {
    // from map.js, waiting for client to lmk which orgList is correct for LA
  //   [
  //     "Behavioral Health",
  //     "CAC",
  //     "DCFS",
  //     "District Attorney's Office",
  //     "Drop-In Center",
  //     "DV/SA Services",
  //     "Emergency Shelter",
  //     "FINS",
  //     "Homelessness Program",
  //     "HT Program, Non-Residential",
  //     "HT Program, Residential",
  //     "Immigrant Services",
  //     "Juvenile Justice",
  //     "Law Enforcement",
  //     "Legal Assistance",
  //     "Long Term Residential",
  //     "Medical",
  //     "School",
  //     "Taskforce",
  //     "Transitional Housing",
  //     "Other",
  //     "National Referral Directory",
  // ]
      
    orgList: [
      { name: "Behavioral Health", color: 0},
      { name: "CAC", color: 1},
      { name: "DCFS", color: 2},
      { name: "District Attorney's Office", color: 3},
      { name: "Drop-In Center", color: 4},
      { name: "DV/SA Services", color: 5},
      { name: "Emergency Shelter", color: 6},
      { name: "FINS", color: 7},
      { name: "Homelessness Program", color: 8},
      { name: "HT Program, Non-Residential", color: 9},
      { name: "HT Program, Residential", color: 10},
      { name: "Immigrant Services", color: 11},
      { name: "Juvenile Justice", color: 12},
      { name: "Law Enforcement", color: 13},
      { name: "Legal Assistance", color: 14},
      { name: "Long Term Residential", color: 15},
      { name: "Medical", color: 16},
      { name: "School", color: 17},
      { name: "Taskforce", color: 18},
      { name: "Therapy/Counseling", color: 19},
      { name: "Transitional Housing", color: 20},
      { name: "Victim Advocacy", color: 21},
      { name: "Other", color: 22},
      { name: "National Referral Directory", color: 23},
    ],

    countyList: parishList2,
    regionList: laRegionList,

    'Provider Location ': { // info based on filter selection
      'State': {
        mapJson: null,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'Parish',
        popUpOrgTypeName: 'Organization Type',
        featureLocProp: '', // ???
      },
      'Parish': {
        mapJson: parishData,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'Parish',
        popUpOrgTypeName: 'Organization Type',
        featureLocProp: 'COUNTY', // ???
      },
      'Region': {
        mapJson: laRegionData,
        dataVar: 'orgList',
        locSourceVar: 'region',
        popUpLocName: 'Parish',
        popUpOrgTypeName: 'Organization Type',
        featureLocProp: 'region', // ???
      },
    },
  }, // Services

  'Screenings': {
    mapJson: parishJson,
    dataVar: 'counties',
    dataTotalVar: 'countiesTotal',
    matchDataLoc: 'county',
    matchJsonLoc: 'COUNTY',
    displayMapData: [
      {
        name: 'Total',
        dataVar: 'total',
        totalsDataVar: 'total',
        featureProp: 'SCREENINGS'
      },
      {
        name: 'Clear Concern',
        dataVar: 'clearconcern',
        totalsDataVar: 'clearConcern',
        featureProp: 'CLEARCONCERN',
        percent: 'clearConcernPercent'
      },
      {
        name: 'Possible Concern',
        dataVar: 'possibleconcern',
        totalsDataVar: 'possibleConcern',
        featureProp: 'POSSIBLECONCERN',
        percent: 'possibleConcernPercent'
      },
      {
        name: 'No Concern',
        dataVar: 'noconcern',
        totalsDataVar: 'noConcern',
        featureProp: 'NOCONCERN',
        percent: 'noConcernPercent'
      },
    ],
    organizations: laScreeningsOrgs,
    countyList: parishList,
    regionList: laRegionList,
    agesOptions: ['0-4', '5-9', '10-14', '15-19', '20-24', '25-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80+'],

    mapInfo: <div>
      <p className='margin0'><span className='fontWeight700'>Data Source:</span> WestCoast screenings</p>
      <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2020-present</p>

      <p className='margin0'><span className='fontWeight700'>Data Source:</span> Lighthouse CSE-IT Screenings</p>
      <p className='margin0'><span className='fontWeight700'>Date:</span> 2022-present</p>

      <p>
        This data source represents CSE-IT screenings, including
        historic data submitted by Louisiana agencies to WestCoast
        Children’s Clinic, and screenings submitted directly in
        Lighthouse starting in 2022.
                  </p>
      <p>
        Organizations may complete more than one CSE-IT for an
        individual as needed, or the same individual may be screened
        by more than one organization. Data represents the location
        where the screening occurred or the location of the agency
        that performed the screening. Some screenings may have
        occurred in locations that are different from the location
        of the agency that performed the screening. Lighthouse
        screenings are updated dynamically.
                  </p>
      <p>Any screening values which are less than six will be shown as zero in the Screenings map and charts.</p>
    </div>,
  },

  'Locations': {
    countyList: parishList,
    regionList: laRegionList,
  },

  'Federal Cases': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Map View': mapsNationalData['Federal Cases']['Map View'],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },

  'Law Enforcement': {
    countyList: parishList,
    regionList: laRegionList,
  },

  'State Policy': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Data': mapsNationalData['State Policy']['Data'],
    'CriminalData': mapsNationalData['State Policy']['CriminalData'],
    mapInfo: mapsNationalData['State Policy'].mapInfo
  },

  'Hotline': {
    countyList: parishList,
    regionList: laRegionList,
  },

  'Businesses of Interest': {
    countyList: parishList,
    regionList: laRegionList,
    'Map View': {
      'Parish': {
        mapFillColors: [
          [1, "#e9f6fc"],
          [5, "#93d6f1"],
          [10, "#28ade3"],
          [15, "#186788"],
          [20, "#0c3344"],
          [25, "#000000"],
        ],
      },
      'Growth Rate': {
// the higher the growth rate percentage, the darker the county. Positive rates are on the generic blue gradient, negative rates are on the secondary gradient.
        mapFillColors: [
          [-300, "#297C63"],
          // [-250, "#"],
          [-100, "#AEE0C6"],
          [0, "#e9f6fc"],
          [100, "#93d6f1"],
          [250, "#28ade3"],
          [300, "#000000"],
        ],
      },
    },
  },
}