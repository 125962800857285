export const flRegionJson = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "id": 5,
            "properties": {
                "GEO_ID": 5,
                "STATE": "Florida",
                "REGION": "Orlando",
                "id": 5
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.56935231102086,
                            28.586484967984262
                        ],
                        [
                            -81.10192309187428,
                            29.427055206688046
                        ],
                        [
                            -81.15588095992732,
                            29.41095410652738
                        ],
                        [
                            -81.15008109760277,
                            29.265956653258726
                        ],
                        [
                            -81.41728994701462,
                            29.261155821742904
                        ],
                        [
                            -81.43399180165517,
                            29.39855221061892
                        ],
                        [
                            -81.45089186405686,
                            29.378463848872677
                        ],
                        [
                            -81.68090262407404,
                            29.324430252220473
                        ],
                        [
                            -81.64190847913204,
                            29.276759162685785
                        ],
                        [
                            -81.63915074337044,
                            29.06288008080348
                        ],
                        [
                            -81.65869743066624,
                            28.96034528102981
                        ],
                        [
                            -81.95420795898264,
                            28.960365889639522
                        ],
                        [
                            -81.95764082721655,
                            28.345179497582357
                        ],
                        [
                            -81.81890289523811,
                            28.361711905847898
                        ],
                        [
                            -81.657268,
                            28.347098
                        ],
                        [
                            -81.657189,
                            28.259217
                        ],
                        [
                            -81.551345,
                            28.249045
                        ],
                        [
                            -81.524083,
                            28.201012
                        ],
                        [
                            -81.524227,
                            28.142805
                        ],
                        [
                            -81.455647,
                            28.142894
                        ],
                        [
                            -81.455315,
                            28.085367
                        ],
                        [
                            -81.375467,
                            28.0851
                        ],
                        [
                            -81.378718,
                            28.011743
                        ],
                        [
                            -81.308696,
                            27.92188
                        ],
                        [
                            -81.301862,
                            27.862081
                        ],
                        [
                            -81.208124,
                            27.821125
                        ],
                        [
                            -81.131495,
                            27.6494
                        ],
                        [
                            -81.14126934189223,
                            27.644820832506586
                        ],
                        [
                            -80.87315,
                            27.642288
                        ],
                        [
                            -80.7780282924488,
                            27.64317751418767
                        ],
                        [
                            -80.77714669875716,
                            27.55881128600463
                        ],
                        [
                            -80.67983873915287,
                            27.55841010094347
                        ],
                        [
                            -80.67742147079814,
                            27.1219548069333
                        ],
                        [
                            -80.88565085679741,
                            26.95893138913516
                        ],
                        [
                            -80.24672874978816,
                            26.957188923155503
                        ],
                        [
                            -80.07953069509294,
                            26.970499552786272
                        ],
                        [
                            -80.0890468674147,
                            27.007033135290406
                        ],
                        [
                            -80.36109104385969,
                            27.705845007881912
                        ],
                        [
                            -80.58386711065931,
                            28.407266151103585
                        ],
                        [
                            -80.5201106835132,
                            28.453019873909764
                        ],
                        [
                            -80.56935231102086,
                            28.586484967984262
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "id": 4,
            "properties": {
                "GEO_ID": 4,
                "STATE": "Florida",
                "REGION": "Miami",
                "id": 4
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            [
                                -80.250581,
                                25.34193
                            ],
                            [
                                -80.221991,
                                25.397417
                            ],
                            [
                                -80.204198,
                                25.412244
                            ],
                            [
                                -80.184033,
                                25.468587
                            ],
                            [
                                -80.192336,
                                25.473331
                            ],
                            [
                                -80.21428,
                                25.416988
                            ],
                            [
                                -80.246307,
                                25.398603
                            ],
                            [
                                -80.256982,
                                25.361239
                            ],
                            [
                                -80.292567,
                                25.314385
                            ],
                            [
                                -80.342683,
                                25.268125
                            ],
                            [
                                -80.333787,
                                25.253891
                            ],
                            [
                                -80.3498,
                                25.210595
                            ],
                            [
                                -80.391909,
                                25.19221
                            ],
                            [
                                -80.387164,
                                25.170859
                            ],
                            [
                                -80.41326,
                                25.137053
                            ],
                            [
                                -80.447659,
                                25.147729
                            ],
                            [
                                -80.433575,
                                25.106317
                            ],
                            [
                                -80.524498,
                                25.016945
                            ],
                            [
                                -80.545971,
                                25.01477
                            ],
                            [
                                -80.537995,
                                24.990244
                            ],
                            [
                                -80.494781,
                                25.023019
                            ],
                            [
                                -80.493881,
                                25.038502
                            ],
                            [
                                -80.428318,
                                25.095547
                            ],
                            [
                                -80.399767,
                                25.108536
                            ],
                            [
                                -80.349855,
                                25.168825
                            ],
                            [
                                -80.351399,
                                25.190615
                            ],
                            [
                                -80.250581,
                                25.34193
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -81.582923,
                                24.658732
                            ],
                            [
                                -81.614529,
                                24.650584
                            ],
                            [
                                -81.637087,
                                24.621408
                            ],
                            [
                                -81.678595,
                                24.597647
                            ],
                            [
                                -81.794057,
                                24.586
                            ],
                            [
                                -81.814446,
                                24.56358
                            ],
                            [
                                -81.810333,
                                24.544701
                            ],
                            [
                                -81.691575,
                                24.559886
                            ],
                            [
                                -81.664209,
                                24.573143
                            ],
                            [
                                -81.602998,
                                24.586444
                            ],
                            [
                                -81.54645,
                                24.614895
                            ],
                            [
                                -81.511165,
                                24.625135
                            ],
                            [
                                -81.49858,
                                24.66498
                            ],
                            [
                                -81.480951,
                                24.645121
                            ],
                            [
                                -81.448623,
                                24.640172
                            ],
                            [
                                -81.414187,
                                24.647167
                            ],
                            [
                                -81.395096,
                                24.621062
                            ],
                            [
                                -81.303113,
                                24.651665
                            ],
                            [
                                -81.319282,
                                24.701238
                            ],
                            [
                                -81.36041,
                                24.708788
                            ],
                            [
                                -81.38558,
                                24.726182
                            ],
                            [
                                -81.392947,
                                24.743371
                            ],
                            [
                                -81.4527,
                                24.736209
                            ],
                            [
                                -81.451881,
                                24.714518
                            ],
                            [
                                -81.5124,
                                24.703737
                            ],
                            [
                                -81.535323,
                                24.67954
                            ],
                            [
                                -81.562917,
                                24.692912
                            ],
                            [
                                -81.582923,
                                24.658732
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -82.15068,
                                24.576331
                            ],
                            [
                                -82.159439,
                                24.548212
                            ],
                            [
                                -82.116787,
                                24.549144
                            ],
                            [
                                -82.099417,
                                24.572522
                            ],
                            [
                                -82.104187,
                                24.588256
                            ],
                            [
                                -82.143075,
                                24.593395
                            ],
                            [
                                -82.15068,
                                24.576331
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.909954,
                                24.781154
                            ],
                            [
                                -80.986454,
                                24.752749
                            ],
                            [
                                -81.02217,
                                24.733091
                            ],
                            [
                                -81.041797,
                                24.742965
                            ],
                            [
                                -81.066816,
                                24.723926
                            ],
                            [
                                -81.125371,
                                24.708291
                            ],
                            [
                                -81.108041,
                                24.688592
                            ],
                            [
                                -81.078439,
                                24.692382
                            ],
                            [
                                -81.064554,
                                24.715453
                            ],
                            [
                                -81.03429,
                                24.727341
                            ],
                            [
                                -81.015933,
                                24.719881
                            ],
                            [
                                -80.938543,
                                24.767535
                            ],
                            [
                                -80.909954,
                                24.781154
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -81.317673,
                                24.75729
                            ],
                            [
                                -81.350162,
                                24.746524
                            ],
                            [
                                -81.326844,
                                24.728375
                            ],
                            [
                                -81.288259,
                                24.720881
                            ],
                            [
                                -81.305468,
                                24.756612
                            ],
                            [
                                -81.317673,
                                24.75729
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.788263,
                                24.824218
                            ],
                            [
                                -80.79278,
                                24.843918
                            ],
                            [
                                -80.830158,
                                24.81428
                            ],
                            [
                                -80.796053,
                                24.81194
                            ],
                            [
                                -80.788263,
                                24.824218
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.873096,
                                25.805377
                            ],
                            [
                                -81.344564,
                                25.803322
                            ],
                            [
                                -81.340406,
                                25.786631
                            ],
                            [
                                -81.361875,
                                25.772715
                            ],
                            [
                                -81.343984,
                                25.747668
                            ],
                            [
                                -81.346078,
                                25.721473
                            ],
                            [
                                -81.328935,
                                25.717233
                            ],
                            [
                                -81.290328,
                                25.687506
                            ],
                            [
                                -81.240677,
                                25.613629
                            ],
                            [
                                -81.225557,
                                25.55847
                            ],
                            [
                                -81.203175,
                                25.53416
                            ],
                            [
                                -81.208201,
                                25.504937
                            ],
                            [
                                -81.168652,
                                25.463848
                            ],
                            [
                                -81.146765,
                                25.407577
                            ],
                            [
                                -81.15412439794954,
                                25.35948050459388
                            ],
                            [
                                -81.19720356103778,
                                25.225208365343732
                            ],
                            [
                                -81.10822400512664,
                                25.104692575480048
                            ],
                            [
                                -80.8481385722545,
                                25.139936033027116
                            ],
                            [
                                -80.69374646555885,
                                25.10494789511094
                            ],
                            [
                                -80.47227099679974,
                                25.18464602319198
                            ],
                            [
                                -80.30201108395268,
                                25.371586578063337
                            ],
                            [
                                -80.21160972701627,
                                25.733674265437607
                            ],
                            [
                                -80.17937591061067,
                                25.72956491877028
                            ],
                            [
                                -80.18980228805361,
                                25.685244261790878
                            ],
                            [
                                -80.14932248190175,
                                25.65837714684528
                            ],
                            [
                                -80.11899648515109,
                                25.762295330330424
                            ],
                            [
                                -80.01858689632522,
                                26.812178854589906
                            ],
                            [
                                -80.07953069305832,
                                26.970500044146497
                            ],
                            [
                                -80.24672804783182,
                                26.957189027964336
                            ],
                            [
                                -80.88565108407126,
                                26.958931546259443
                            ],
                            [
                                -80.873096,
                                25.805377
                            ]
                        ]
                    ]
                ],
                "type": "MultiPolygon"
            }
        },
        {
            "type": "Feature",
            "id": 3,
            "properties": {
                "REGION": "Tampa Bay",
                "id": 3,
                "GEO_ID": 3,
                "STATE": "Florida"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -81.95420792872238,
                            28.960365840147205
                        ],
                        [
                            -82.31169644202046,
                            28.960391003513138
                        ],
                        [
                            -82.42699807224977,
                            29.02138620738664
                        ],
                        [
                            -82.53559013001504,
                            29.044855161383367
                        ],
                        [
                            -82.61265280025796,
                            29.009271294031166
                        ],
                        [
                            -82.68981349174334,
                            29.033961604940096
                        ],
                        [
                            -82.75896939915981,
                            28.99329079531961
                        ],
                        [
                            -82.86604888155465,
                            28.180829652735014
                        ],
                        [
                            -82.8705155010335,
                            27.85021049401918
                        ],
                        [
                            -82.76695362684177,
                            27.60444922462389
                        ],
                        [
                            -82.5545136759076,
                            27.645158638418913
                        ],
                        [
                            -82.0543412466795,
                            27.646426509789663
                        ],
                        [
                            -82.05576100913633,
                            27.33824746497778
                        ],
                        [
                            -81.56364102170366,
                            27.34067549306028
                        ],
                        [
                            -81.56321944684768,
                            27.64672955259209
                        ],
                        [
                            -81.14127014563779,
                            27.644820561587572
                        ],
                        [
                            -81.13148291737733,
                            27.649376748330877
                        ],
                        [
                            -81.20812380804932,
                            27.821125047841285
                        ],
                        [
                            -81.30186198081557,
                            27.862080866288494
                        ],
                        [
                            -81.30869608452457,
                            27.921880084749066
                        ],
                        [
                            -81.37871809405844,
                            28.011743103768815
                        ],
                        [
                            -81.3754672445915,
                            28.085099685028794
                        ],
                        [
                            -81.45531497967694,
                            28.085366781241063
                        ],
                        [
                            -81.4556471633663,
                            28.142893802391974
                        ],
                        [
                            -81.52422680794838,
                            28.142804946223603
                        ],
                        [
                            -81.52408309847321,
                            28.201012024630558
                        ],
                        [
                            -81.55134488677534,
                            28.24904492907448
                        ],
                        [
                            -81.6571891763846,
                            28.2592168129162
                        ],
                        [
                            -81.65726801581208,
                            28.347098249425134
                        ],
                        [
                            -81.8189026725147,
                            28.361711994154106
                        ],
                        [
                            -81.9576409992531,
                            28.34517970179337
                        ],
                        [
                            -81.95420792872238,
                            28.960365840147205
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "id": 1,
            "properties": {
                "REGION": "Fort Myers",
                "id": 1,
                "GEO_ID": 1,
                "STATE": "Florida"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -82.76695330245295,
                            27.604449442802064
                        ],
                        [
                            -82.75388422909796,
                            27.540797753602007
                        ],
                        [
                            -82.20319769925537,
                            26.48148717023227
                        ],
                        [
                            -82.1263894595546,
                            26.383668714599054
                        ],
                        [
                            -81.98301407878036,
                            26.453984122188544
                        ],
                        [
                            -81.80494563714937,
                            26.090864070990904
                        ],
                        [
                            -81.73162194075348,
                            25.907827669906013
                        ],
                        [
                            -81.68909795021524,
                            25.8386065604318
                        ],
                        [
                            -81.36279324043754,
                            25.773426415704975
                        ],
                        [
                            -81.34038990387086,
                            25.786980494192548
                        ],
                        [
                            -81.3456406858788,
                            25.80399970100663
                        ],
                        [
                            -80.87301114563266,
                            25.805710502941807
                        ],
                        [
                            -80.88565116816189,
                            26.95893167148384
                        ],
                        [
                            -80.67742167174434,
                            27.121954785511576
                        ],
                        [
                            -80.67983856334021,
                            27.558410352162014
                        ],
                        [
                            -80.77714670642135,
                            27.558811200672025
                        ],
                        [
                            -80.77802821088994,
                            27.643177528382097
                        ],
                        [
                            -81.56321946968772,
                            27.646729286400344
                        ],
                        [
                            -81.5636409117544,
                            27.340675299432817
                        ],
                        [
                            -82.05576125084954,
                            27.338247567593996
                        ],
                        [
                            -82.05434081456791,
                            27.646426337093274
                        ],
                        [
                            -82.55451334865575,
                            27.64515874111497
                        ],
                        [
                            -82.76695330245295,
                            27.604449442802064
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "id": 2,
            "properties": {
                "REGION": "Jacksonville",
                "id": 2,
                "GEO_ID": 2,
                "STATE": "Florida"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -81.42269826102154,
                            30.708568094525376
                        ],
                        [
                            -81.47475791379902,
                            30.713960670961853
                        ],
                        [
                            -81.48604795897954,
                            30.72528410004064
                        ],
                        [
                            -81.53308981389877,
                            30.722588166115585
                        ],
                        [
                            -81.53685316229246,
                            30.708028820300356
                        ],
                        [
                            -81.54688875800842,
                            30.719892156801762
                        ],
                        [
                            -81.60835678176987,
                            30.727979958573712
                        ],
                        [
                            -81.62591907427262,
                            30.73444971142702
                        ],
                        [
                            -81.66292533347587,
                            30.75331734287461
                        ],
                        [
                            -81.73254727875609,
                            30.752778318978045
                        ],
                        [
                            -81.7413284250081,
                            30.766253011122444
                        ],
                        [
                            -81.79464252724952,
                            30.78673093042015
                        ],
                        [
                            -81.81017999170926,
                            30.791135001678526
                        ],
                        [
                            -81.88481973484775,
                            30.811068925936453
                        ],
                        [
                            -81.89109198217064,
                            30.82561269338518
                        ],
                        [
                            -81.90238202735112,
                            30.8315373005306
                        ],
                        [
                            -81.94001551128638,
                            30.82938294023529
                        ],
                        [
                            -81.94942388227034,
                            30.82938294023529
                        ],
                        [
                            -81.96385005111199,
                            30.817533094470832
                        ],
                        [
                            -81.9738856468285,
                            30.777663793755096
                        ],
                        [
                            -82.00838300710204,
                            30.792751419821542
                        ],
                        [
                            -82.01779137808602,
                            30.792751419821542
                        ],
                        [
                            -82.02594529960538,
                            30.786285584202588
                        ],
                        [
                            -82.0146552544249,
                            30.759878910480538
                        ],
                        [
                            -82.03660812005394,
                            30.755027917380843
                        ],
                        [
                            -82.04601649103789,
                            30.726456001162305
                        ],
                        [
                            -82.05228873836013,
                            30.65633807936939
                        ],
                        [
                            -82.0271996582252,
                            30.604159960936883
                        ],
                        [
                            -82.00650124206071,
                            30.5658226384558
                        ],
                        [
                            -82.02030018617036,
                            30.52909099386453
                        ],
                        [
                            -82.03948965854612,
                            30.43594664248198
                        ],
                        [
                            -82.04638913060093,
                            30.405657663599698
                        ],
                        [
                            -82.03948965854612,
                            30.372112473419563
                        ],
                        [
                            -82.07022367042654,
                            30.363453780810204
                        ],
                        [
                            -82.10409380596869,
                            30.369406714342446
                        ],
                        [
                            -82.12855557052687,
                            30.369947872147975
                        ],
                        [
                            -82.16807072865866,
                            30.359665361708906
                        ],
                        [
                            -82.21072201045217,
                            30.426752767799684
                        ],
                        [
                            -82.20005919000366,
                            30.486767711426495
                        ],
                        [
                            -82.24021812665626,
                            30.542798629200888
                        ],
                        [
                            -82.21450191263348,
                            30.5692646824604
                        ],
                        [
                            -82.45979236893459,
                            30.5842664033174
                        ],
                        [
                            -82.45836387764747,
                            30.136449234749776
                        ],
                        [
                            -82.57600952148671,
                            29.99146784237928
                        ],
                        [
                            -82.52970491590554,
                            29.94088105605715
                        ],
                        [
                            -82.65855461756296,
                            29.83014388312523
                        ],
                        [
                            -82.8004767659546,
                            29.932126186774752
                        ],
                        [
                            -82.86424584659537,
                            29.909836252545382
                        ],
                        [
                            -82.87980217913744,
                            29.886846889848083
                        ],
                        [
                            -82.87561595186514,
                            29.866526996549766
                        ],
                        [
                            -82.89287188219794,
                            29.82746009264958
                        ],
                        [
                            -82.92060815910855,
                            29.824142921632458
                        ],
                        [
                            -82.94970693280939,
                            29.75440872534001
                        ],
                        [
                            -82.93737345210657,
                            29.5913104290329
                        ],
                        [
                            -82.98437105524904,
                            29.471003786157574
                        ],
                        [
                            -83.06852135523138,
                            29.343953028578685
                        ],
                        [
                            -83.16609091257246,
                            29.28888000378703
                        ],
                        [
                            -83.09144760771149,
                            29.233562089289283
                        ],
                        [
                            -83.11620948133765,
                            29.110442801562634
                        ],
                        [
                            -83.03880197625205,
                            29.081036850672405
                        ],
                        [
                            -82.9559821373928,
                            29.160246093782963
                        ],
                        [
                            -82.82245415334164,
                            29.149987872688605
                        ],
                        [
                            -82.82401253541802,
                            29.07696603091
                        ],
                        [
                            -82.75823235236331,
                            29.052926378691346
                        ],
                        [
                            -82.77000817047607,
                            29.000016030423815
                        ],
                        [
                            -82.75896933055098,
                            28.993290791945476
                        ],
                        [
                            -82.68981337930195,
                            29.03396152332046
                        ],
                        [
                            -82.61265278179847,
                            29.009271282234934
                        ],
                        [
                            -82.53559056435842,
                            29.044855291532095
                        ],
                        [
                            -82.42699795307313,
                            29.021386128040515
                        ],
                        [
                            -82.31169646508171,
                            28.960390885901845
                        ],
                        [
                            -81.65869760358814,
                            28.960345274568972
                        ],
                        [
                            -81.63915059186881,
                            29.06288044750675
                        ],
                        [
                            -81.6419085964001,
                            29.27675935389158
                        ],
                        [
                            -81.68090274301193,
                            29.32443022259298
                        ],
                        [
                            -81.45089174097197,
                            29.37846392312095
                        ],
                        [
                            -81.43399185187012,
                            29.398551979602622
                        ],
                        [
                            -81.41728987168561,
                            29.261156038912038
                        ],
                        [
                            -81.1500808391557,
                            29.265956944345703
                        ],
                        [
                            -81.1558810154609,
                            29.410954126888377
                        ],
                        [
                            -81.10192333056605,
                            29.427055186992288
                        ],
                        [
                            -81.42269826102154,
                            30.708568094525376
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "id": 7,
            "properties": {
                "GEO_ID": 7,
                "STATE": "Florida",
                "REGION": "Tallahassee",
                "id": 7
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            [
                                -85.156415,
                                29.679628
                            ],
                            [
                                -85.208981,
                                29.681775
                            ],
                            [
                                -85.142746,
                                29.635404
                            ],
                            [
                                -85.097218,
                                29.633004
                            ],
                            [
                                -85.077237,
                                29.670862
                            ],
                            [
                                -85.114268,
                                29.688658
                            ],
                            [
                                -85.156415,
                                29.679628
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -82.4597925122168,
                                30.584266399841265
                            ],
                            [
                                -83.52026872765906,
                                30.646917069931682
                            ],
                            [
                                -84.86469388371846,
                                30.711541769951943
                            ],
                            [
                                -84.99001686015575,
                                30.524794696084946
                            ],
                            [
                                -84.98320273100816,
                                30.441836700395303
                            ],
                            [
                                -85.0590923286805,
                                30.263227644829634
                            ],
                            [
                                -85.11565967723566,
                                30.199976364748647
                            ],
                            [
                                -85.15377313267881,
                                30.091293945189733
                            ],
                            [
                                -85.11369023314101,
                                30.023172523696836
                            ],
                            [
                                -85.025439,
                                29.971399
                            ],
                            [
                                -85.012191,
                                29.873167
                            ],
                            [
                                -85.040078,
                                29.779346
                            ],
                            [
                                -85.109401,
                                29.776793
                            ],
                            [
                                -85.217355,
                                29.694953
                            ],
                            [
                                -85.177284,
                                29.700193
                            ],
                            [
                                -85.101682,
                                29.718748
                            ],
                            [
                                -85.037212,
                                29.711074
                            ],
                            [
                                -84.993264,
                                29.714961
                            ],
                            [
                                -84.964007,
                                29.742422
                            ],
                            [
                                -84.93837,
                                29.750211
                            ],
                            [
                                -84.91511,
                                29.783303
                            ],
                            [
                                -84.877111,
                                29.772888
                            ],
                            [
                                -84.901781,
                                29.735723
                            ],
                            [
                                -84.824197,
                                29.758288
                            ],
                            [
                                -84.762998,
                                29.788846
                            ],
                            [
                                -84.692053,
                                29.829059
                            ],
                            [
                                -84.669728,
                                29.82891
                            ],
                            [
                                -84.603303,
                                29.876117
                            ],
                            [
                                -84.535873,
                                29.910092
                            ],
                            [
                                -84.494562,
                                29.913957
                            ],
                            [
                                -84.451705,
                                29.929085
                            ],
                            [
                                -84.434287,
                                29.906328
                            ],
                            [
                                -84.378937,
                                29.893112
                            ],
                            [
                                -84.343389,
                                29.899539
                            ],
                            [
                                -84.333746,
                                29.923721
                            ],
                            [
                                -84.341261,
                                29.960775
                            ],
                            [
                                -84.31902645794912,
                                30.059698618606006
                            ],
                            [
                                -84.29359881612997,
                                30.056326693654
                            ],
                            [
                                -84.27490546644927,
                                30.05805523817024
                            ],
                            [
                                -84.25568997786492,
                                30.09412595142843
                            ],
                            [
                                -84.16675180966068,
                                30.065726136129165
                            ],
                            [
                                -84.02591408001254,
                                30.100727351085
                            ],
                            [
                                -83.94129496912691,
                                30.040089019456232
                            ],
                            [
                                -83.64538744240787,
                                29.883989899472724
                            ],
                            [
                                -83.58602192704008,
                                29.737285810511892
                            ],
                            [
                                -83.41217344532433,
                                29.65489327459484
                            ],
                            [
                                -83.40873602242469,
                                29.501476820979967
                            ],
                            [
                                -83.29253434462731,
                                29.434270312667163
                            ],
                            [
                                -83.2386248691069,
                                29.427171887861306
                            ],
                            [
                                -83.16609067496063,
                                29.28888024608987
                            ],
                            [
                                -83.06852101649517,
                                29.343953434789242
                            ],
                            [
                                -82.98437088559824,
                                29.471004466169422
                            ],
                            [
                                -82.93737346867256,
                                29.591310044808345
                            ],
                            [
                                -82.94970706617964,
                                29.754408717793826
                            ],
                            [
                                -82.92060849665677,
                                29.824142886147257
                            ],
                            [
                                -82.89287171838947,
                                29.8274600689252
                            ],
                            [
                                -82.87561604223795,
                                29.866526773959393
                            ],
                            [
                                -82.87980240186249,
                                29.886846723574227
                            ],
                            [
                                -82.86424598361408,
                                29.909836234146965
                            ],
                            [
                                -82.80047680154303,
                                29.932126416052633
                            ],
                            [
                                -82.65855460021834,
                                29.83014385129617
                            ],
                            [
                                -82.5297050766021,
                                29.940881334136005
                            ],
                            [
                                -82.57600994779246,
                                29.99146778569645
                            ],
                            [
                                -82.45836385607765,
                                30.136449167230865
                            ],
                            [
                                -82.4597925122168,
                                30.584266399841265
                            ]
                        ]
                    ]
                ],
                "type": "MultiPolygon"
            }
        },
        {
            "type": "Feature",
            "id": 6,
            "properties": {
                "GEO_ID": 6,
                "STATE": "Florida",
                "REGION": "Pensacola",
                "id": 6
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -86.919292,
                            30.370675
                        ],
                        [
                            -86.52438412590058,
                            30.38563531802486
                        ],
                        [
                            -86.33119052756074,
                            30.36411000941505
                        ],
                        [
                            -86.03762777370156,
                            30.279206407018105
                        ],
                        [
                            -85.87645916989452,
                            30.21294068041047
                        ],
                        [
                            -85.51700028323171,
                            29.968789999180174
                        ],
                        [
                            -85.36317576397585,
                            29.894222454133175
                        ],
                        [
                            -85.42072395428315,
                            29.866205028484423
                        ],
                        [
                            -85.418289652845,
                            29.77973437725471
                        ],
                        [
                            -85.37384472885408,
                            29.677744323475963
                        ],
                        [
                            -85.35162226685863,
                            29.66029558908648
                        ],
                        [
                            -85.22418864911936,
                            29.677346054606584
                        ],
                        [
                            -85.21735494659204,
                            29.69495307764427
                        ],
                        [
                            -85.10940113508279,
                            29.776792913189944
                        ],
                        [
                            -85.04007814345296,
                            29.779345872075723
                        ],
                        [
                            -85.01219088487801,
                            29.873167405984542
                        ],
                        [
                            -85.02543873520965,
                            29.971398772877684
                        ],
                        [
                            -85.1136904750989,
                            30.023172505486066
                        ],
                        [
                            -85.1537730231962,
                            30.091294004440524
                        ],
                        [
                            -85.11565976044515,
                            30.199976225427275
                        ],
                        [
                            -85.0590924170631,
                            30.263227919441572
                        ],
                        [
                            -84.98320273706639,
                            30.441836922158636
                        ],
                        [
                            -84.99001684943453,
                            30.524794763101312
                        ],
                        [
                            -84.9325189508516,
                            30.60639296524024
                        ],
                        [
                            -84.86469381963309,
                            30.711541509295078
                        ],
                        [
                            -84.89624615391821,
                            30.75056637530623
                        ],
                        [
                            -84.91226606868007,
                            30.75217881250836
                        ],
                        [
                            -84.91980297372271,
                            30.765798263246083
                        ],
                        [
                            -84.91741176379965,
                            30.777171162236176
                        ],
                        [
                            -84.92759936110413,
                            30.795630011394106
                        ],
                        [
                            -84.93624819719088,
                            30.83444538059384
                        ],
                        [
                            -84.9254336648465,
                            30.84036848664976
                        ],
                        [
                            -84.93478238505436,
                            30.883682350476988
                        ],
                        [
                            -84.98231536429249,
                            30.93461694395166
                        ],
                        [
                            -84.97878799715357,
                            30.961176536962768
                        ],
                        [
                            -85.00677251538627,
                            30.974837871749937
                        ],
                        [
                            -85.00190081073265,
                            31.000680764251676
                        ],
                        [
                            -86.0572554279796,
                            30.993410879726703
                        ],
                        [
                            -87.163084,
                            30.999051
                        ],
                        [
                            -87.598928,
                            30.997457
                        ],
                        [
                            -87.594111,
                            30.976335
                        ],
                        [
                            -87.601355,
                            30.936294
                        ],
                        [
                            -87.634938,
                            30.865886
                        ],
                        [
                            -87.624137,
                            30.845713
                        ],
                        [
                            -87.532607,
                            30.743489
                        ],
                        [
                            -87.481225,
                            30.716508
                        ],
                        [
                            -87.407118,
                            30.671796
                        ],
                        [
                            -87.394479,
                            30.625192
                        ],
                        [
                            -87.449921,
                            30.51469
                        ],
                        [
                            -87.430578,
                            30.491096
                        ],
                        [
                            -87.419177,
                            30.410198
                        ],
                        [
                            -87.440678,
                            30.391498
                        ],
                        [
                            -87.451378,
                            30.367199
                        ],
                        [
                            -87.452378,
                            30.300201
                        ],
                        [
                            -87.419859,
                            30.297128
                        ],
                        [
                            -87.295422,
                            30.323503
                        ],
                        [
                            -87.267827,
                            30.31548
                        ],
                        [
                            -87.155392,
                            30.327748
                        ],
                        [
                            -86.919292,
                            30.370675
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        }
    ]
}