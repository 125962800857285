import React, { useEffect, useRef, useState, useCallback, Fragment } from "react";
import throttle from "lodash/throttle";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterSummary from "../../../Pages/Dashboard/Filters/FilterSummary";
import Filter from "../../../Pages/Dashboard/Filters/Filter";
import Slider from "@material-ui/core/Slider";

import "./ChartMapControls.scss";

export default function ChartMapControls(props) {
    const filterButton = useRef(null);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mapName, setMapName] = useState("");
    const [mapChartFilters, setMapChartFilters] = useState([]);

    const filterWrapper = useRef(null);

    const handleScroll = useCallback(() => {
        if (!filterWrapper.current) {
            return;
        }

        const learnAboutDropdown = document.querySelectorAll(".public-site-select-dropdown")[0];

        const dropdownRect = learnAboutDropdown.getBoundingClientRect();
        const scrolled = document.scrollingElement.scrollTop;
        const { bottom } = filterWrapper.current.getBoundingClientRect();

        if (scrolled >= bottom) {
            filterWrapper.current.classList.add("sticky");
            filterWrapper.current.style.top = `${dropdownRect.bottom + 24}px`;
        } else {
            filterWrapper.current.classList.remove("sticky");
            filterWrapper.current.style.top = "unset";
        }
    }, [filterWrapper]);

    const throttledHandleScroll = throttle(handleScroll, 50);

    const handleFilterClick = (e, filter) => {
        if (e.currentTarget.name === open) {
            setOpen(null);
            setAnchorEl(null);
        } else {
            setOpen(filter);
            setAnchorEl(e.currentTarget);
        }
    };

    const handleClose = () => {
        setOpen(null);
    };

    // Filter Callback
    const handleFilterCallback = (item) => {
        const selectedFilters = props.selectedFilters;
        var newArray = [...selectedFilters];
        for (let i = 0; i < newArray.length; i++) {
        if (
            newArray[i].name === item.name &&
            newArray[i].filter === item.filter
        ) {
            newArray[i].value = item.value;
            if (newArray[i].selection) {
            newArray[i].selection = item.selection;
            if (item.customTime) {
                newArray[i].customTime = item.customTime;
            } else if (item.stateValue || item.districtValue || item.countyValue) {
                newArray[i].stateValue = item.stateValue
                newArray[i].districtValue = item.districtValue
                newArray[i].countyValue = item.countyValue
            }
            }
        }
        }
        props.setSelectedFilters(newArray);
    };

    const handleUpdateSummaryFilterItem = (item) => {
        setOpen(false)
        props.setSelectedFilters(item);
    };

    useEffect(() => {
      if (props.className !== "global-filter") {
        return;
      }

      window.addEventListener("resize", throttledHandleScroll);
      document.addEventListener("scroll", throttledHandleScroll);

      return () => {
          window.addEventListener("resize", throttledHandleScroll);
          document.removeEventListener("scroll", throttledHandleScroll);
      }
  }, [throttledHandleScroll, props.className]);

    useEffect(() => {
        const { mapNameToMapInfo, mapName } = props;
        if (!mapNameToMapInfo || !mapName) {
            return;
        }

        const mapInfo = mapNameToMapInfo[mapName];
        setMapName(mapInfo?.secondary);
        setMapChartFilters(
          props.isMap
            ? mapInfo?.mapFilters
            : mapInfo?.chartFilters
        );
    }, [props]);

    return (
        <div ref={filterWrapper} className={`map-controls ${props.className}`}>
            <div className="filter-controls">
                <Popper
                  open={open === "main"}
                  anchorEl={anchorEl}
                  placement={"bottom-start"}
                  transition
                  className="public-site-popper-main popper-main"
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <FilterSummary
                          filters={mapChartFilters}
                          selectedFilters={props.selectedFilters}
                          handleUpdateSummaryFilterItem={
                            handleUpdateSummaryFilterItem
                          }
                          data={mapName}
                        />
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                <Button
                  ref={filterButton}
                  onClick={(e) => handleFilterClick(e, "main")}
                  className="filterButton"
                  name="main"
                >
                  <FilterListIcon />
                </Button>
                {
                    mapChartFilters?.map((filter, key) => {
                        return filter?.type === 'slider'
                          ? (<Fragment></Fragment>)
                          : (
                          <div key={key}>
                            <Popper
                              open={open === filter}
                              anchorEl={anchorEl}
                              placement={"bottom-start"}
                              transition
                              className="popper"
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={50}>
                                  <Paper>
                                    <Filter
                                      filter={filter}
                                      filterCallback={handleFilterCallback}
                                      selectedFilters={props.selectedFilters}
                                      handleClose={handleClose}
                                      data={mapName}
                                      styles={{
                                        container: {
                                          className: "public-site-filter-container"
                                        }
                                      }}
                                    />
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                            <Button
                              ref={filterButton}
                              onClick={(e) => handleFilterClick(e, filter)}
                              className="filterButton"
                              name={filter}
                            >
                              {typeof filter === "string" ? filter : filter.name} <ArrowDropDownIcon />
                            </Button>
                          </div>
                        );
                    })
                }
            </div>
            <div className="map-title">
        </div>

        {props.isMap && mapName === 'State Policy' &&
          <div style={{ marginRight: 50 }}>
          <p className='yearSliderText'>{props.statePolicyYear}</p>
            <Slider
              className='yearSlider'
              aria-label="year"
              value={props.statePolicyYear}
              onChange={(e, newValue) => props.setStatePolicyYear(newValue)}
              onChangeCommitted={() => props.stateFilterCallback({
                name: 'Year',
                filter: 'State Policy',
                value: [`${props.statePolicyYear}`]
              })}
              // valueLabelDisplay="auto"
              step={1}
              min={2003}
              max={2023} />
          </div>
        }
        {props.isMap && props.mapData?.stateClickedData &&
          <button className='btn pointer nationalViewBtn' onClick={() => props.stateFilterCallback({
            name: 'Map View',
            filter: 'Federal Cases',
            value: ['State Prosecuted'],
            stateClickedData: null
          })}>
            [ x ] National View
          </button>
        }
        </div>
    );
};