import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TableWithTotal(props) {
  const classes = useStyles();

  let data = props.data

  let valueProp = data[0].total ? 'total' : 'value'

  if (props.descending) {
    data = props.data.sort((a, b) =>
      b[valueProp] - a[valueProp])
  }

  if (props.ascending) {
    data = props.data.sort((a, b) =>
      a.xaxis - b.xaxis
  )}

  if (props.alphabeticalSort) {
    data = props.data.sort((a, b) => b[props.nameKey] > a[props.nameKey] ? -1 : 1)
  }

  if (props.horizontalSort) {
    data = props.data.sort((a, b) => {
      if (a[valueProp] === b[valueProp]) {
        return a[props.nameKey] < b[props.nameKey] ? -1 : 1;
      }
      return b[valueProp] - a[valueProp];
    })
  }

  let total = 0

  if (props.toFixed) {
    total = data?.map((data) => Number(data?.[valueProp]))?.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(props.toFixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if (!props.noTotalRow) {
    total = data?.map((data) => Number(data?.[valueProp]))?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  // For tables that need to have totals calculated on the backend
  if (props.tableTotal) {
    total = props.tableTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // For tables with column totals
  let variableArray = [];
  if (props.totalEachColumn) {
    for (let i = 0; i < props.columnKeys.length; i++) {
      variableArray.push(data?.map((data) => Number(data[props.columnKeys[i]] ?? 0)).reduce((accumulator, currentValue) => accumulator + currentValue, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    }
  }

  if (props.totalLastColumnSource) {
    total = data?.map((data) => Number(data?.[props.totalLastColumnSource]))?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  // console.log(variableArray)

  return (
    <TableContainer
      component={Paper}
      id={`${props.id}`}    
      style={{ marginTop: '25px'}}
    >
      <Table className={classes.table} aria-label="simple table" style={{ tableLayout: props.evenColumns ? 'fixed' : 'auto'}}>
        <TableHead>
          {props.title &&
            <TableRow>
            <TableCell colSpan={props.headers?.length}>
              <h3 className="fontWeight700">{props.title}</h3>
              </TableCell>
            </TableRow>
          }
          <TableRow>
            {props.headers?.map((header, key) => {
              let textAlign = ''
              if (key === 0) {
                textAlign = 'left'
              } else if (props.columnKeys.length > 2) {
                textAlign = 'center'
              } else {
                textAlign = 'right'
              }
              return (
                <TableCell key={key} align={textAlign} style={{ textAlign: textAlign }} className="table-header">
                  {header}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((data, keyA) => {
            return (
              <TableRow key={keyA}>
                {props.columnKeys?.map((columnKey, keyB) => {
                  if (keyB !== props.columnKeys.length - 1) {
                    if (props.keys) {
                      return (
                        <TableCell key={keyB} align={keyB === 0 ? 'left' : 'center'} style={{ textAlign: keyB === 0 ? 'left' : 'center'}}>
                          {props.keys?.filter((key) => key.variable === data[columnKey])[0]?.name}
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell key={keyB} align={keyB === 0 ? 'left' : 'center'} style={{ textAlign: keyB === 0 ? 'left' : 'center'}}>
                          {keyB === 0 ? data[columnKey] : data[columnKey]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      )
                    }
                  } else { // 'Value'
                    return (
                      <TableCell key={keyB} align={props.columnKeys.length > 2 ? 'center' : 'right'} style={{ textAlign: props.columnKeys.length > 2 ? 'center' : 'right'}}>
                        {data[columnKey]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            )
          })}
          {!props.noTotalRow &&
            <TableRow>
            {props.columnKeys?.map((c, ci) => {
              if (ci === 0) {
                return (
                  <TableCell key={c} className="table-header">Total</TableCell>
                )
              } else if (!props.customTotalRowData && !props.totalEachColumn && ci === props.columnKeys.length - 1) {
                return (
                  <TableCell key={c} className="table-header" align={props.columnKeys.length > 2 ? 'center' : 'right'} style={{ textAlign: props.columnKeys.length > 2 ? 'center' : 'right'}}>
                    {total}
                  </TableCell>
                )
              } else if (props.totalEachColumn) {
                return (
                  <TableCell key={c} className="table-header" align="center" style={{ textAlign: 'center'}}>
                    {variableArray[ci]}
                  </TableCell>
                )
              } else if (props.customTotalRowData) {
                return(
                  <TableCell key={c} className="table-header" align={props.tableTotal ? 'right' : 'center'} style={{ textAlign: props.tableTotal ? 'right' : 'center'}}>
                  {props.customTotalRowData[c]}
                  </TableCell>
                )
              } else if (props.totalLastColumnSource && ci === props.columnKeys.length - 1) {
                return (
                  <TableCell key={c} className="table-header" align={props.columnKeys.length > 2 ? 'center' : 'right'} style={{ textAlign: props.columnKeys.length > 2 ? 'center' : 'right' }}>
                    {total}
                  </TableCell>
                )
              } else {
                return (
                  <TableCell></TableCell>
                )
              }
            })
            }
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
