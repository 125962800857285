import { countyData } from "../../../Data/countyJson";
import { parishData } from "../../../Data/parishJson";
import { flCountyData } from "../../../Data/flCountyJson";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";


export function ImbLayer(map, filters, data, stateLocation, mapsIndex) {
    let mapViewSelection = filters.filter(f => f.name === 'Map View' && f.filter === 'Businesses of Interest')[0]?.value?.[0]

    let mapDataIndex = mapsIndex['Businesses of Interest']['Map View'][mapViewSelection]

    let yearSelection = filters.filter(f => f.name === 'Year' && f.filter === 'Businesses of Interest')[0]?.value

    if (mapViewSelection === 'Growth Rate' && !yearSelection[1]) {
        // get default years when map view first changes
        yearSelection = [stateLocation === 'FL' ? 2019 : 2016, 2024]
    }

    if (data.counties) {
        let mapJson;
        if (stateLocation === 'TX') {
            mapJson = JSON.parse(JSON.stringify(countyData));
        } else if (stateLocation === 'LA') {
            mapJson = JSON.parse(JSON.stringify(parishData));
        } else {
            mapJson = JSON.parse(JSON.stringify(flCountyData));
        }

        mapJson.features.forEach(feature => {
            feature.properties.mainValue = 0

            if (mapViewSelection === 'Growth Rate') {
                feature.properties.yearOne = '0'
                feature.properties.yearTwo = '0'
            }
            data.counties?.forEach(d => {
                if (feature.properties.COUNTY === d.county) {
                    feature.properties.mainValue = Number(d.total);
                    if (mapViewSelection === 'Growth Rate') {
                        feature.properties.yearOne = d.year_one
                        feature.properties.yearTwo = d.year_two
                    }
                }
            })
        })

        let calculatedData = mapJson;
        map.on("load", function () {
            map.addSource("county", {
                type: "geojson",
                data: calculatedData,
            });
            map.addLayer({
                id: "county",
                type: "line",
                source: "county",
                layout: {
                    "line-join": "round",
                    "line-cap": "round",
                },
                paint: {
                    "line-color": "#28ADE3",
                    "line-width": 1,
                },
            });

            map.addLayer({
                id: "county-fills",
                type: "fill",
                source: "county",
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "fill-color": {
                        property: "mainValue",
                        stops: mapDataIndex.mapFillColors

                    },
                    "fill-outline-color": "#28ADE3",
                    "fill-opacity": 0.9,
                },
            });
        });

        let hoveredStateId = null;
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
            if (hoveredStateId !== e.features[0].id) {
                if (e.features.length > 0) {
                    if (hoveredStateId) {
                        map.setFeatureState(
                            { source: "county", id: hoveredStateId },
                            { hover: false }
                        );
                    }
                    hoveredStateId = e.features[0].id;
                    map.setFeatureState(
                        { source: "county", id: hoveredStateId },
                        { hover: true }
                    );
                }
                let description = mapViewSelection !== 'Growth Rate' ?
                    `
                        <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> 
                        <p>Businesses: <span style="font-weight: 300 !important">${e.features[0].properties.mainValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }</span></p>
                        <p>Percent: <span style="font-weight: 300 !important">${(((e.features[0].properties.mainValue / data.countiesTotal[0].sum) * 100)) ?
                            (((e.features[0].properties.mainValue / data.countiesTotal[0].sum) * 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
                        }%</span></p>
                    `
                    :
                    `
                        <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> 
                        <p>${yearSelection[0]}: <span style="font-weight: 300 !important">${e.features[0].properties.yearOne}</span></p>
                        <p>${yearSelection[1]}: <span style="font-weight: 300 !important">${e.features[0].properties.yearTwo}</span></p>
                        <p>Growth Rate: <span style="font-weight: 300 !important">${e.features[0].properties.mainValue}%</span></p>
                    `

                popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
            }
        });

        map.on("mouseleave", "county-fills", function () {
            if (hoveredStateId) {
                map.setFeatureState(
                    { source: "county", id: hoveredStateId },
                    { hover: false }
                );
            }
            hoveredStateId = null;
            popup.remove();
        });
    }

}